import request from '@/utils/requestV2'

// 按行业分组查询业主的销售额
export function listContractGroupByIndustryGroup (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listContractGroupByIndustryGroup',
    method: 'get',
    params: data
  })
}
// 获取目标签约额
export function countTargetContractAmount (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countTargetContractAmount',
    method: 'get',
    params: data
  })
}
// 获取签约额
export function countKpiContractAmount (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countKpiContractAmount',
    method: 'get',
    params: data
  })
}
// 获取业主KPI设置签约额完成率
export function countTargetContractFinishRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countTargetContractFinishRate',
    method: 'get',
    params: data
  })
}
// 统计合同额--按用户分组
export function listContractAmountGroupByUser (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listContractAmountGroupByUser',
    method: 'get',
    params: data
  })
}
// 个人能力综合排名
export function countAbilityRankingGbUser (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countAbilityRankingGbUser',
    method: 'get',
    params: data
  })
}
// 获取销售员签约客户详情
export function listUserSignAdvertiser (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listUserSignAdvertiser',
    method: 'get',
    params: data
  })
}
// 销售员的合同额按月统计
export function listUserContractAmountGroupByDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listUserContractAmountGroupByDate',
    method: 'get',
    params: data
  })
}
// 获取用户当前报备的客户数-按行业分组
export function countHaveAdvertiserNumberGbIndustry (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countHaveAdvertiserNumberGbIndustry',
    method: 'get',
    params: data
  })
}
// 获取用户当前报备的客户数
export function countHaveAdvertiserNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countHaveAdvertiserNumber',
    method: 'get',
    params: data
  })
}
// 签约的客户数
export function countUserSignAdvertiserNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countUserSignAdvertiserNumber',
    method: 'get',
    params: data
  })
}
// 新增报备的客户数
export function countAddAdvertiserNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countAddAdvertiserNumber',
    method: 'get',
    params: data
  })
}
// 获取用户当前报备的品牌数-按行业分组
export function countBrandNumberGbIndustry (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countBrandNumberGbIndustry',
    method: 'get',
    params: data
  })
}
// 获取用户当前报备的品牌数
export function countHaveBrandNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countHaveBrandNumber',
    method: 'get',
    params: data
  })
}
// 签约的品牌数
export function countSignBrandNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countSignBrandNumber',
    method: 'get',
    params: data
  })
}
// 新增报备的品牌数
export function countAddBrandNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/countAddBrandNumber',
    method: 'get',
    params: data
  })
}
// 获取年度执行额与结算额及比例-按季度分组
export function listExecuteAndBillGbQuarter (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listExecuteAndBillGbQuarter',
    method: 'get',
    params: data
  })
}
// 统计合同额-按行业分组
export function listContractAmountGbIndustry (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listContractAmountGbIndustry',
    method: 'get',
    params: data
  })
}
// 获取销售员的个人能力雷达图-新接口
export function listPersonalAbilityRankingForKPI (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listPersonalAbilityRanking',
    method: 'get',
    params: data
  })
}
// 获取年度执行额与结算完成率-按季度分组
export function listYearTargetRateGbQuarter (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listYearTargetRateGbQuarter',
    method: 'get',
    params: data
  })
}
// 获取业主的KPI的标题(青岛KPI专用)
export function listKpiTitle (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listKpiTitle',
    method: 'get',
    params: data
  })
}
// 获取业主的KPI的数据(青岛KPI专用)
export function listKpiData (data) {
  return request({
    url: '/ooh-dw/v1/datav/kpi/listKpiData',
    method: 'get',
    params: data
  })
}
