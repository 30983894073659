<template>
  <div>
    <Row class="text-center p-t-10">
      <i-col span="8">
        <p class="text-14">报备品牌数</p>
        <b class="text-24">{{brandNumber}}</b>
      </i-col>
      <i-col span="8">
        <p class="text-14">签约品牌数</p>
        <b class="text-24">{{signBrandNumber}}</b>
      </i-col>
      <i-col span="8">
        <p class="text-14">新增品牌数</p>
        <b class="text-24">{{addBrandNumber}}</b>
      </i-col>
    </Row>

    <div style="width:100%;height:180px;" id="kpiCenterRightReport"></div>
    <Row class="table-title p-l-10 p-r-10" :gutter="8" style="margin: 20px 10px 0 10px;">
      <i-col span="12">行业</i-col>
      <i-col span="6">品牌数量</i-col>
      <i-col span="6" class="text-right">占比</i-col>
    </Row>
    <Row v-for="(item,index) in brandListByIndustry" :key="index" :gutter="8" class="p-t-5 p-l-20 p-r-20">
      <i-col span="12">{{item.name}}</i-col>
      <i-col span="6">{{item.number}}</i-col>
      <i-col span="6" class="text-right">{{item.rate}}%</i-col>
    </Row>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
      <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
    <p v-if="brandListByIndustry.length===0" class="remark p-t-20 text-center">暂无数据</p>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { countBrandNumberGbIndustry, countHaveBrandNumber, countSignBrandNumber, countAddBrandNumber } from '@/api/dw/kpi'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      brandListByIndustry: [],
      showArray: [],
      currentPageSize: 10,
      currentPageNumber: 1,
      rightDataSourcePie: null,

      brandNumber: 0,
      signBrandNumber: 0,
      addBrandNumber: 0
    }
  },
  mounted () {
    this.loadBrandCount()
    this.loadBrandNumberByDate()
  },
  methods: {
    loadBrandCount () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        industryGroupId: this.gbType !== '2' ? this.gbId : null,
        userId: this.gbType === '2' ? this.gbId : null
      }

      countHaveBrandNumber(queryModel).then(res => {
        this.brandNumber = res[0].value
      })
      countBrandNumberGbIndustry(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)

        const reportData = res.map(element => {
          return {
            value: element.rate,
            name: element.name
          }
        })
        this.initRightChart(reportData)
      })
    },
    loadBrandNumberByDate () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        industryGroupId: this.gbType !== '2' ? this.gbId : null,
        userId: this.gbType === '2' ? this.gbId : null
      }

      countSignBrandNumber(queryModel).then(res => {
        this.signBrandNumber = res[0].value
      })
      countAddBrandNumber(queryModel).then(res => {
        this.addBrandNumber = res[0].value
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.brandListByIndustry = newArray
    },
    initRightChart (reportData) {
      if (this.rightDataSourcePie != null && this.rightDataSourcePie !== '' && this.rightDataSourcePie !== undefined) {
        this.rightDataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('kpiCenterRightReport')) {
          this.rightDataSourcePie = echarts.init(document.getElementById('kpiCenterRightReport'))
          const option = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '品牌分行业占比',
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    formatter: '{d}%\n{b}',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: reportData
              }
            ]
          }

          this.rightDataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.rightDataSourcePie.resize()
          })

          // 默认选中第一项
          let index = 0
          const _self = this
          _self.rightDataSourcePie.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: 0
          }) // 设置默认选中高亮部分
          _self.rightDataSourcePie.on('mouseover', function (e) {
            if (e.dataIndex !== index) {
              _self.rightDataSourcePie.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
              })
            }
          })
          _self.rightDataSourcePie.on('mouseout', function (e) {
            index = e.dataIndex
            _self.rightDataSourcePie.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: e.dataIndex
            })
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    gbType () {
      return this.$store.state.situationWeb.gbType
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadBrandNumberByDate()
      }
    },
    companyId () {
      this.loadBrandCount()
      this.loadBrandNumberByDate()
    },
    gbType () {
      this.loadBrandCount()
      this.loadBrandNumberByDate()
    },
    gbId () {
      this.loadBrandCount()
      this.loadBrandNumberByDate()
    }
  }
}
</script>

<style>

</style>
